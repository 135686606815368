const HOST = '';

export const ws_chat = ``;

export const api_logout = `${HOST}/api/user/logout`;
export const api_login = `${HOST}/api/user/login`;
export const api_register = `${HOST}/api/user/register`;

export const External_Google = '';
export const External_Github = '';

export default HOST;